import style from "./MySkills.module.scss"
import {Container} from "../X_common/Container/Container";
import {H2} from "../X_common/H2/H2";
import {SphereOfTagsComponent} from "./SphereOfTags/SphereOfTags";

export const MySkills = () => {
    return (
        <Container id="My Skills"
                   className={style.mySkills}
        >
            <div className={style.innerWrapper}>
                <H2 title="My Skills"/>

                <div className={style.canvasWrapper}>
                    <SphereOfTagsComponent/>
                </div>
            </div>



        </Container>


    )
}