import style from "./AnimatedTitle.module.scss";
import {clsx} from "clsx";
import {useLayoutEffect, useRef} from "react";
import gsap from "gsap";
import TweenTarget = gsap.TweenTarget;
import { useGSAP } from "@gsap/react";

export const AnimatedTitle = () => {
    const appRef = useRef<HTMLDivElement>(null!);

    const labels = [
        "Web developer",
        "Frontender",
        "Backender",
        "Freelancer",
    ]

    const duration = 0.15;
    const delay = 1;


    useGSAP((self) => {
        const timeline = gsap.timeline({ repeat: -1 });

        for (let i = 0; i < labels.length; i++) {
            // @ts-ignore
            const letters = self.selector(`.title_${i} .letter`) as TweenTarget[];

            letters.forEach((letter, index) => {
                timeline.fromTo(letter, {
                        opacity: 0,
                        translateY: "-50%",
                    },
                    {
                        opacity: 1,
                        translateY: "0%",
                        duration,
                    },
                )
            })

            for (let i = letters.length - 1; i >= 0; i--) {
                timeline.to(letters[i],
                    {
                        opacity: 0,
                        translateX: "80%",
                        translateY: "-60%",
                        scale: 1.24,
                        filter: "blur(5px)",
                        duration,
                        delay: (i === letters.length - 1) ? delay : 0, // delay before disappear
                    },
                )
            }
        }
    }, { scope: appRef })

    return (
        <div className={style.animatedTitle} ref={appRef}>
            {
                labels.map((label, key) => (
                    <p key={key}
                       className={clsx(style.title, `title_${key}`)}
                    >
                        {
                            label.split("").map((l, key) => (
                                <span key={key}
                                      className={clsx({
                                          "letter": l !== " ",
                                          [style.empty]: l === " ",
                                      })}
                                >
                        {l}
                    </span>
                            ))
                        }
                    </p>
                ))
            }


        </div>
    )
}