import {FC, useRef} from "react";
import {clsx} from "clsx";
import style from "./WaveLink.module.scss";
import gsap from "gsap";
import {CustomEase} from "gsap/CustomEase";
import {HashLink} from "react-router-hash-link";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(CustomEase);

interface IWaveLink {
    label: string
}

export const WaveLink: FC<IWaveLink> = ({label}) => {
    const appRef = useRef<HTMLParagraphElement>(null!);
    const tweenRef = useRef<gsap.core.Tween>(null!);

    CustomEase.create("custom", "M0,0 C0,0 0.204,1 0.5,1 0.801,1 1,0 1,0 ");

    const duration = 0.3; //0.4 * 4 / label.length;
    const stagger = duration / 4;

    const { contextSafe } = useGSAP(() => {
        tweenRef.current = gsap.to(".title .l", {
                scale: 1.4,
                color: "#00FF00FF",
                duration,
                stagger,
                ease: "custom"
            }
        )
        tweenRef.current.pause();
    }, { scope: appRef })

    const onMouseEnter = contextSafe(() => tweenRef.current.play(0));

    const onMouseLeave = () => {
        //tweenRef.current.reverse();
    }

    return (
        <HashLink to={`/#${label}`}
                  smooth
                  className={style.waveLink}
        >
            <p className="title"
               ref={appRef}
               onMouseEnter={onMouseEnter}
               onMouseLeave={onMouseLeave}
            >
                {
                    label.split("").map((l, key) => (
                        <span key={key}
                              className={clsx({
                                  "l": l !== " ",
                                  [style.empty]: l === " ",
                              })}
                        >
                        {l}
                    </span>
                    ))
                }
            </p>
        </HashLink>
    )
}