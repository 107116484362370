import style from "./Contact.module.scss"
import {Container} from "../X_common/Container/Container";
import {H2} from "../X_common/H2/H2";
import {Form, Formik, FormikErrors, FormikProps} from "formik";
import {FormikHelpers} from "formik/dist/types";
import {FieldCustom} from "../X_common/FieldCustom/FieldCustom";
import {svgIcons} from "../../assets/svgIcons";
import {IMailValues} from "../../types/mail.types";
import {mailAPI} from "../../api/api";
import {useRef, useState} from "react";
import {useGSAP} from "@gsap/react";
import {clsx} from "clsx";
import gsap from "gsap";

const mailRegexp = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

export const Contact = () => {
    const [loading, setLoading] = useState(false);

    const initialValues: IMailValues = {
        name: "",
        email: "",
        message: "",
    }

    const validate = ({name, email, message}: IMailValues): FormikErrors<IMailValues> => {
        const errors: FormikErrors<IMailValues> = {};

        if (!name) {
            errors.name = "Name cannot be empty"
        }

        if (!mailRegexp.test(email)) {
            errors.email = "Invalid Email"
        }

        if (!email) {
            errors.email = "Email cannot be empty"
        }

        if (!message) {
            errors.message = "Message cannot be empty"
        }

        return errors
    }

    const onSubmit = async (values: IMailValues, formikHelpers: FormikHelpers<IMailValues>) => {
        try {
            console.log(values);
            setLoading(true);
            await mailAPI.sendEmail(values);
        } catch (e) {
            console.log(e)
        } finally {
            formikHelpers.resetForm();
            setLoading(false);
        }
    }

    const appRef = useRef<HTMLButtonElement>(null!);
    useGSAP(() => {
        gsap.fromTo(".submitButton svg", {
            x: -3,
            y: -3
        }, {
            x: 3,
            y: 3,
            duration: 1,
            repeat: -1,
            yoyo: true,
        })
    }, {scope: appRef})

    return (
        <Container id="Contact"
                   className={style.contact}
        >
            <div className={style.content}>
                <div className={style.contentInner}>
                    <div className={style.left}>
                        <div className={style.titleWrapper}>
                            <H2 title="Contact"/>
                        </div>
                        <p className={style.title}>
                            Got a problem to solve?
                        </p>
                        <p className={style.text}>
                            Get your space suit ready and tell me your ideas to develop your dream website.
                        </p>
                    </div>

                    <div className={style.right}>
                        <Formik initialValues={initialValues}
                                onSubmit={onSubmit}
                                validate={validate}
                        >
                            {(props: FormikProps<IMailValues>) => (
                                <Form autoComplete="off">
                                    <FieldCustom name="name" label="Name" icon={svgIcons.user}/>
                                    <FieldCustom name="email" label="Email" icon={svgIcons.mail}/>
                                    <FieldCustom name="message" label="Message" icon={svgIcons.message}/>
                                    <button type="submit"
                                            className={clsx(style.submitButton, "submitButton")}
                                            disabled={props.isSubmitting || loading}
                                            ref={appRef}
                                    >
                                        <span className={style.label}>
                                            {loading ? "Sending..." : "Hit me up"}
                                        </span>
                                        {svgIcons.paperPlain}
                                        <div className={style.boxWrapper}>
                                            <div className={style.box}/>
                                        </div>
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

            </div>

        </Container>
    )
}