import style from "./FieldCustom.module.scss";
import {DetailedHTMLProps, FC, InputHTMLAttributes, ReactElement, useRef, useState} from "react";
import {useField} from "formik";
import {clsx} from "clsx";
import {svgIcons} from "../../../assets/svgIcons";
// import {useGSAP} from "@gsap/react";
// import gsap from "gsap";

interface IFieldCustom extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    icon: JSX.Element
    label: string
    name: string
}

export const FieldCustom: FC<IFieldCustom> = ({icon, label, ...props}) => {

    const [field, meta, helpers] = useField(props.name);

    const [focus, setFocus] = useState(false);

    const onFocus = () => setFocus(true);

    // const appRef = useRef<HTMLDivElement>(null!);
    // useGSAP(() => {
    //     gsap.to(".error", {
    //         opacity: meta.touched && meta.error ? 1 : 0,
    //         duration: 2,
    //     })
    // }, {
    //     scope: appRef,
    //     dependencies: [meta.touched, meta.error]
    // })


    return (
        <div className={style.fieldCustom}
             //ref={appRef}
        >
            <p className={style.label}>
                {label}
            </p>
            <div className={clsx({
                [style.inputWrapper]: true,
                [style.inputWrapper_focus]: focus && !(meta.touched && meta.error),
                [style.inputWrapper_error]: meta.touched && meta.error,
            })}>
                <input {...props}
                       name={field.name}
                       value={field.value}
                       onChange={field.onChange}
                       onFocus={onFocus}
                       onBlur={(e) => {
                           setFocus(false);
                           field.onBlur(e);
                       }}
                />

                <div className={style.iconWrapper}>
                    {icon}
                </div>

                {
                    meta.touched && meta.error && (
                        <div className={clsx(style.errorWrapper, "error")}>
                            {svgIcons.exclamation}
                            <p className={style.error}>{meta.error}</p>
                        </div>

                    )
                }
            </div>

        </div>
    )
}