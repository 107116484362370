import style from "./Header.module.scss";
import {HashLink} from 'react-router-hash-link';
import {links} from "../../constants/constants";
import {FC, useEffect, useState} from "react";
import {clsx} from "clsx";
import {WaveLink} from "../X_common/WaveLink/WaveLink";
import {svgIcons} from "../../assets/svgIcons";

interface IHeader {
    menu: boolean
    onClick: () => void
}

export const Header: FC<IHeader> = ({
                                        menu,
                                        onClick,
                                    }) => {
    const [position, setPosition] = useState(window.scrollY)
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            let moving = window.scrollY;
            setVisible(position > moving);
            setPosition(moving);
        };
        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    })


    return (
        <header className={clsx({
            [style.header]: true,
            [style.header_scroll]: position > 0 && !menu,
            [style.header_hide]: !visible && !menu,

        })}>
            <div className={style.inner}>
                <HashLink to="/#top"
                          smooth
                          className={style.logo}
                >
                    Alex Altrex
                </HashLink>

                <button className={style.burgerBtn}
                        onClick={onClick}
                >
                    {menu ? svgIcons.close : svgIcons.menu}
                </button>

                <nav className={style.links}>
                    {
                        links.map((link, key) => (
                            <WaveLink key={key} label={link}/>
                        ))
                    }
                </nav>
            </div>
        </header>
    )
}