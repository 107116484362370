import style from "./ContactMeButton.module.scss";
import {HashLink} from "react-router-hash-link";

export const ContactMeButton = () => {

    return (
        <HashLink className={style.contactMeButton}
                  to={"/#Contact"}
                  smooth
        >
            <div className={style.items}>
                {
                    [0, 1, 2, 3].map(value => (
                        <div
                            key={value}
                            style={{
                                width: "25%",
                                height: "300%",
                                left: `${value * 25}%`,
                            }}
                            className={style.item}
                        />
                    ))
                }
            </div>
            <span className={style.label}>Contact me</span>

        </HashLink>
    )
}