import style from "./Building.module.scss"
import {useLayoutEffect, useRef} from "react";
import gsap from "gsap";
import {clsx} from "clsx";
import {CustomEase} from "gsap/CustomEase";
import {useGSAP} from "@gsap/react";
import {svgIcons} from "../../../assets/svgIcons";

gsap.registerPlugin(CustomEase);

export const Building = () => {
    const appRef = useRef<HTMLDivElement>(null!);
    const duration = 1.5;
    const duration2 = 0.5;
    const delay = 0.5;

    CustomEase.create("custom_bounce", "M0,0 C0.14,0 0.242,0.438 0.272,0.561 0.313,0.728 0.354,0.963 0.362,1 0.37,0.985 0.36,0.985 0.402,0.924 0.463,0.832 0.575,0.861 0.601,0.881 0.676,0.938 0.719,0.981 0.726,0.998 0.788,0.914 0.84,0.936 0.859,0.95 0.878,0.964 0.897,0.985 0.911,0.998 0.922,0.994 0.939,0.984 0.954,0.984 0.969,0.984 1,1 1,1 ");

    useGSAP(() => {

        const timeline = gsap.timeline({repeat: -1});

        timeline.fromTo(".footer", {
            bottom: "101%",
        }, {
            bottom: "10%",
            duration,
            ease: "custom_bounce"
        })

        timeline.fromTo(".sidebar", {
            bottom: "101%",
        }, {
            bottom: "19%",
            duration,
            ease: "custom_bounce"
        })

        timeline.fromTo(".main", {
            bottom: "101%",
        }, {
            bottom: "19%",//"19%"
            duration,
            ease: "custom_bounce"
        })

        timeline.fromTo(".header", {
            bottom: "101%",
        }, {
            bottom: "83%",
            duration,
            ease: "custom_bounce"
        })

        // FOOTER
        timeline.to(".footer .mask", {
            opacity: 0,
            scaleX: 1.1,
            scaleY: 2,
            filter: "blur(5px)",
            duration: duration2,
            delay: 1,
        })

        timeline.to(".footer", {
            backgroundColor: "#8194dc",
            boxShadow: "3px 3px 5px #0b0b21",
            duration: 0.5,
            delay: 0.1,
        })

        timeline.fromTo(".footer .copyright", {
            opacity: 0,
            y: -5,
        }, {
            opacity: 1,
            y: -0,
            duration: 0.3,
        })

        timeline.fromTo(".footer .link", {
            opacity: 0,
            y: -5,
        }, {
            opacity: 1,
            y: -0,
            duration: 0.3,
            stagger: 0.3,
        })

        // SIDEBAR
        timeline.to(".sidebar .mask", {
            opacity: 0,
            scaleX: 1.1,
            scaleY: 1.2,
            filter: "blur(5px)",
            duration: duration2,
            delay,
        })

        timeline.to(".sidebar", {
            backgroundColor: "rgba(63,183,131,0.34)",
            boxShadow: "3px 3px 5px #0b0b21",
            duration: 0.5,
            delay: 0.1,
        })

        timeline.fromTo(".sidebar .topic", {
            filter: "blur(2px)",
            opacity: 0,
            x: 50,
        }, {
            filter: "blur(0)",
            opacity: 1,
            x: 0,
            duration: 0.3,
            stagger: 0.3,
        })

        // MAIN
        timeline.to(".main .mask", {
            opacity: 0,
            scaleX: 1.1,
            scaleY: 1.2,
            filter: "blur(5px)",
            duration: duration2,
            delay,
        })

        timeline.to(".main", {
            backgroundColor: "#647e56",
            boxShadow: "3px 3px 5px #0b0b21",
            duration: 0.5,
            delay: 0.1,
        })

        timeline.fromTo(".main .item", {
            filter: "blur(2px)",
            opacity: 0,
            scale: 1.2,
        }, {
            filter: "blur(0)",
            opacity: 1,
            scale: 1,
            duration: 0.3,
            stagger: 0.3,
        })

        // HEADER
        timeline.to(".header .mask", {
            opacity: 0,
            scaleX: 1.1,
            scaleY: 2,
            filter: "blur(5px)",
            duration: duration2,
            delay,
        })

        timeline.to(".header", {
            backgroundColor: "#4f55ff",
            boxShadow: "3px 3px 5px #0b0d23",
            duration: 0.5,
            delay: 0.1,
        })

        timeline.fromTo(".header .logo", {
            opacity: 0,
            y: -5,
        }, {
            opacity: 1,
            y: -0,
            duration: 0.3,
        })

        timeline.fromTo(".header .link", {
            opacity: 0,
            y: -5,
        }, {
            opacity: 1,
            y: -0,
            duration: 0.3,
            stagger: 0.3,
        })

        //timeline.addPause();

        timeline.to(".content", {
            x: "100%",
            duration,
            delay: 4
        })

    }, {scope: appRef})

    return (
        <div className={style.building}
             ref={appRef}
        >
            <div className={style.maskTop}/>
            <div className={style.maskBottom}/>
            <div className={style.maskRight}/>

            <div className={clsx(style.content, "content")}>

                <div className={clsx(style.footer, "footer")}>
                    <div className={clsx(style.mask, "mask")}>
                        <p className={style.label}>FOOTER</p>
                    </div>

                    <p className={clsx(style.copyright, "copyright")}>©Copyright</p>
                    <div className={style.links}>
                        <p className={clsx(style.link, "link")}>Link A</p>
                        <p className={clsx(style.link, "link")}>Link B</p>
                        <p className={clsx(style.link, "link")}>Link C</p>
                    </div>
                </div>

                <div className={clsx(style.sidebar, "sidebar")}>
                    <div className={clsx(style.topic, "topic")}>Topic A</div>
                    <div className={clsx(style.topic, "topic")}>Topic B</div>
                    <div className={clsx(style.topic, "topic")}>Topic C</div>

                    <div className={clsx(style.mask, "mask")}>
                        <p className={style.label}>SIDEBAR</p>
                    </div>
                </div>

                <div className={clsx(style.main, "main")}>
                    <div className={clsx(style.block, "block")}>
                        <div className={clsx(style.picture, "item")}>{svgIcons.picture}</div>
                        <div className={style.texts}>
                            <p className={clsx(style.title, "item")}>Title A</p>
                            <p className={clsx(style.text, "item")}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                        </div>
                    </div>

                    <div className={clsx(style.block, "block")}>
                        <div className={clsx(style.picture, "item")}>{svgIcons.picture}</div>
                        <div className={style.texts}>
                            <p className={clsx(style.title, "item")}>Title B</p>
                            <p className={clsx(style.text, "item")}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                        </div>
                    </div>

                    <div className={clsx(style.mask, "mask")}>
                        <p className={style.label}>MAIN</p>
                    </div>
                </div>

                <div className={clsx(style.header, "header")}>
                    <p className={clsx(style.logo, "logo")}>LOGO</p>

                    <div className={style.links}>
                        <p className={clsx(style.link, "link")}>Link A</p>
                        <p className={clsx(style.link, "link")}>Link B</p>
                        <p className={clsx(style.link, "link")}>Link C</p>
                    </div>


                    <div className={clsx(style.mask, "mask")}>
                        <p className={style.label}>HEADER</p>
                    </div>
                </div>

            </div>

        </div>
    )
}