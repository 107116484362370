import style from "./About.module.scss"
import {Container} from "../X_common/Container/Container";
import {H2} from "../X_common/H2/H2";
import {ContactMeButton} from "./ContactMeButton/ContactMeButton";
import {AnimatedTitle} from "./AnimatedTitle/AnimatedTitle";
import {Building} from "./Building/Building";

export const About = () => {
    return (
        <Container id="About"
                   className={style.about}>

            <div className={style.content}>
                <div className={style.left}>
                    <H2 title="About"/>

                    <div className={style.buildingWrapper}>
                        <Building/>
                    </div>

                </div>

                <div className={style.right}>
                    <p className={style.pretitle}>Hi, I'm</p>
                    <p className={style.title}>Alex Altrex</p>
                    <AnimatedTitle/>
                    <ContactMeButton/>
                </div>

                <div className={style.buildingWrapperMobile}>
                    <Building/>
                </div>
            </div>

        </Container>
    )
}


