import axios, {AxiosResponse} from "axios";
import {IMailValues} from "../types/mail.types";

export const baseURL = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_DEV
    : process.env.REACT_APP_API_PROD

export const instance = axios.create({
    baseURL,
    withCredentials: true,
});

export const rootApi = {
    async helloWorld() {
        const response = await instance.get<any, AxiosResponse<string>>("/");
        const d = response.data
        console.log(response)
    }
}

export const mailAPI = {
    sendEmail: async (data: IMailValues) => {
        const response = await instance.post<string>('mail', data );
        return response.data;
    },
}