import {FC, useRef} from "react";
import style from "./H2.module.scss";
import {clsx} from "clsx";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

interface IH2 {
    title: string
}

export const H2: FC<IH2> = ({
                                title
}) => {
    const appRef = useRef<HTMLDivElement>(null!);

    useGSAP(() => {
        // gsap.from(".title .letter", {
        //     duration: 0.5,
        //     opacity: 0,
        //     y: 100,
        //     stagger: 0.075,
        //     scrollTrigger: {
        //         trigger: ".title",
        //         //markers: true,
        //         start: "top bottom-=100px",
        //         end: "top bottom-=100px",
        //         toggleActions: "play none reverse none"
        //     }
        // })

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".title",
                //markers: true,
                start: "top bottom-=100px",
                end: "top bottom-=100px",
                toggleActions: "play none reverse none"
            }
        })

        tl.from(".dot", {
            // opacity: 0,
            // filter: "blur(5px)",
            duration: 2,
            scale: 5,
            ease: "bounce.out",
        })

        tl.from(".title .letter", {
            duration: 0.5,
            opacity: 0,
            y: 100,
            stagger: 0.075,
            delay: -1.75,
        })


    }, { scope: appRef })

    return (
        <div ref={appRef}
             className={style.h2Wrapper}
        >
            <div className={clsx(style.dot, "dot")}/>
            <h2 className={clsx(style.h2, "title")} >
                {
                    title.split("").map((l, key) => (
                        <span key={key}
                              className={clsx({
                                  "letter": l !== " ",
                                  [style.empty]: l === " ",
                              })}
                        >
                        {l}
                    </span>
                    ))
                }
            </h2>
        </div>

    )
}