import style from "./Works.module.scss"
import {Container} from "../X_common/Container/Container";
import {H2} from "../X_common/H2/H2";

export const Works = () => {
    return (
        <Container id="Works"
                   className={style.works}
        >

            <H2 title="Works"/>
        </Container>
    )
}