import style from "./BurgerMenu.module.scss"
import {FC} from "react";
import {clsx} from "clsx";
import {links} from "../../constants/constants";
import {HashLink} from "react-router-hash-link";

interface IBurgerMenu {
    menu: boolean
    onClose: () => void
}

export const BurgerMenu: FC<IBurgerMenu> = ({
    menu,
    onClose
                                            }) => {
    return (
        <div className={clsx({
            [style.burgerMenu]: true,
            [style.burgerMenu_open]: menu,
        })}>
            <nav className={style.links}>
                {
                    links.map((link, key) => (
                        <HashLink key={key}
                            to={`/#${link}`}
                                  smooth
                                  className={style.link}
                                  onClick={onClose}
                        >
                            {link}
                        </HashLink>
                    ))
                }
            </nav>
        </div>
    )
}