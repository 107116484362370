import {Canvas, useFrame} from "@react-three/fiber";
import {Billboard, OrbitControls, PerspectiveCamera, Text} from "@react-three/drei";
import {FC, Suspense, useRef} from "react";
import * as THREE from "three";
import {Vector3} from "@react-three/fiber/dist/declarations/src/three-types";
// @ts-ignore
import font from "../../../assets/fonts/Roboto-Regular.ttf"

export const SphereOfTagsComponent = () => {
    return (
        <Canvas style={{
            width: "100%",
            height: "100%"
        }}
                dpr={[1, 2]}
        >
            <ambientLight intensity={0.5}/>
            <directionalLight position={[-1, 2, 4]} intensity={2}/>
            <PerspectiveCamera makeDefault position={[30, 30, 30]} zoom={1}/>
            <OrbitControls enableRotate={false} enableZoom={false}/>
            <Suspense fallback={null}>
                <SphereOfTags/>
            </Suspense>
            <fog attach="fog" args={['darkslategray', 0, 80]}/>
        </Canvas>
    )
}

//========= SPHERE OF TAGS =========//
const SphereOfTags = () => {
    const ref = useRef<THREE.Group>(null!)

    useFrame((state, delta) => {
            ref.current.rotation.z += -0.001
        }
    )

    return (
        <group ref={ref}>
            {
                getWords().map(({position, label}, key) => (
                    <Word key={key} position={position} label={label}/>
                ))
            }
        </group>
    )
}

//========= WORD =========//
interface IWord {
    label: string
    position: Vector3
}

const Word: FC<IWord> = ({
                             label,
                             position
                         }) => {


    const fontProps = {
        font: font,
        fontSize: 2.0,
        'material-toneMapped': false,
        color: "#90ff6f"
    }

    return (
        <Billboard position={position}>
            <Text {...fontProps}>
                {label}
            </Text>
        </Billboard>
    )
}

//========= GET WORDS =========//
interface IWordData {
    position: Vector3,
    label: string
}

const getWords = (): IWordData[] => {
    const COUNT = 7;
    const RADIUS = 20;
    const spherical = new THREE.Spherical();
    const phiSpan = Math.PI / (COUNT + 1);
    const thetaSpan = (Math.PI * 2) / COUNT;

    const words = [] as IWordData[];

    for (let i = 1; i < COUNT + 1; i++) {
        for (let j = 0; j < COUNT; j++) {
            words.push({
                position: new THREE.Vector3().setFromSpherical(spherical.set(RADIUS, phiSpan * i, thetaSpan * j)),
                label: labels[getRandomIntInclusive(0, labels.length - 1)],
            })
        }
    }
    return words
}

const labels = [
    // 1
    "HTML",
    "css",
    "sass",
    "Java Script",
    "React",
    "Redux",
    "Next.js",
    "Typescript",
    "GreenSock",
    "Mobx",
    // 2
    "Material",
    "axios",
    "Redux-Saga",
    "Formik",
    "Node.js",
    "Express",
    "Passport.js",
    "Nodemailer",
    "SSR",
    "GraphQL",
    // 3
    "Three.js",
    "Solidity",
    "Socket.IO",
    "React Native",
    "git",
    "Moment.js",
    "React-router-dom",
    "Frontend",
    "Backend",
    "Apollo",
    //
    "React Query",
    "Mongoose"
]

const getRandomIntInclusive = (min: number, max: number): number => {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}
