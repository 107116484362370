import React, {useEffect, useState} from 'react';
import style from "./App.module.scss";
import {Header} from "../A1_Header/Header";
import {About} from "../B0_About/About";
import {MySkills} from "../B1_MySkills/MySkills";
import {Works} from "../B2_Works/Works";
import {Contact} from "../B3_Contact/Contact";
import {BurgerMenu} from "../A2_BurgerMenu/BurgerMenu";
import {clsx} from "clsx";
import {rootApi} from "../../api/api";

export const App = () => {
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        const getResponse = async () => {
            try {
                await rootApi.helloWorld()
            } catch (e) {
                console.log(e)
            }
        }
        getResponse().then()
    }, []);

    return (
        <div className={clsx({
            [style.app]: true,
            [style.app_noScroll]: menu,
        })}
             id="top"
        >
            <Header menu={menu} onClick={() => setMenu(!menu)}/>
            <BurgerMenu menu={menu} onClose={() => setMenu(false)}/>
            <About/>
            <MySkills/>
            <Works/>
            <Contact/>
        </div>
    );
}

