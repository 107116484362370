import style from "./Container.module.scss";
import {FC, ReactNode} from "react";
import {clsx} from "clsx";

interface IContainer {
    id: string
    children: ReactNode
    className?: string
}

export const Container: FC<IContainer> = ({
                                              id,
                                              children,
                                              className
                                          }) => {
    return (
        <div className={clsx(style.container, Boolean(className) && className)}
             id={id}
        >
            <div className={style.inner}>
                {children}
            </div>
        </div>
    )
}